<template>
  <div class="formulas-table">
    <div class="formulas-table__title">
      <h2>{{ $t('actions-with-formulas') }}</h2>
    </div>
    <div class="formulas-table__body body">
      <formulas-actions-table-row
          v-for="(item, key) in actions"
          :key="key"
          :item="item"
          :formulas="formulas"
          v-show="actions.length"
          @deleteAction="$emit('deleteAction', $event)"
          @editAction="$emit('editAction', $event)"
          :showMath="key+1 === actions.length && key !== 0 && !addNewActions"
      />
      <formulas-actions-table-new-row
          v-if="addNewActions"
          :formulas="formulas"
          @addNewAction="$emit('addNewAction', $event)"
          @deleteNewAction="addNewActions = false"
      />
    </div>
    <ui-button color="outline" class="formulas-table__action" @click="addNewActions = true">
      <img src="../../../../assets/svg/icon-plus-red.svg" alt="">
      <p>{{ $t('add-line') }}</p>
    </ui-button>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";
import FormulasActionsTableRow from "@/components/common/admin/formulasActions/FormulasActionsTableRow.vue";
import FormulasActionsTableNewRow from "@/components/common/admin/formulasActions/FormulasActionsTableNewRow.vue";
export default {
  name: "FormulasActionsTable",
  components: {FormulasActionsTableNewRow, FormulasActionsTableRow, UiButton},
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    formulas: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      addNewActions: false
    }
  }
}
</script>

<style lang="scss" scoped>
.formulas-table {
  min-width: 950px;
  max-width: 950px;
  width: 100%;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
  border: 1px solid transparent;
  transition: 0.3s;

  &__title {
    padding: 10px 20px;
    width: 100%;
    background: #E21F1F;
    border-radius: 20px 20px 0 0;
    height: 37px;
    min-width: 600px;

    h2 {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 38px 0 20px;
  }

  &__action {
    width: max-content !important;
    padding: 30px 20px !important;

    &:hover {
      text-decoration: none !important;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #CE2121;
    }
  }
}
</style>