<template>
  <div class="formulas-table-row row">
    <div class="row-left">
      <div class="row-left__title">
        <p>Показатель {{ number }}</p>
        <img @click="$emit('deleteRow', data)" src="@/assets/svg/icon-delete-admin.svg" alt=""/>
        <img src="@/assets/images/check-active.png" alt="" @click="editRow" v-if="isEdit"/>
      </div>
      <ui-select :placeholder="$t('not-selected')" v-model="firstSelect" :options="firstSelectOptions" @input="changeFirstSelect"/>
      <ui-select :placeholder="$t('not-selected')" v-model="secondSelect" :options="secondSelectOptions" @input="changeSecondSelect"/>
      <ui-select :placeholder="$t('not-selected')" v-model="thirdSelect" :options="thirdSelectOptions" @input="changeThirdSelect"/>
    </div>
    <div class="row-right">
      <ui-select v-model="mathVal" :options="mathOptions" @input="changeMathSelect" v-if="!showMath"/>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions} from "vuex";

export default {
  name: "FormulasTableRow",
  components: {UiSelect},
  props: {
    number: {
      type: Number,
      default: 0
    },
    tableInfo: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    },
    showMath: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      firstSelect: {},
      secondSelect: {},
      thirdSelect: {},
      mathVal: {},
      firstSelectOptions: [
        {name: this.$t('subtypes'), type: 'Subtype'},
        {name: this.$t('custom-variables'), type: 'SpecKey'},
        {name: this.$t('taxes-and-ratios'), type: 'SpecNumber'}
      ],
      secondSelectOptions: [],
      thirdSelectOptions: [],
      mathOptions: [
        {name: this.$t('multiply'), type: 'Multiply'},
        {name: this.$t('add-math'), type: 'Add'},
      ],
      isEdit: false
    }
  },

  mounted () {
    this.mathOptions.forEach(el => {
      if (el.type === this.data.action) {
        this.mathVal = el
      }
    })
    this.firstSelectOptions.forEach(el => {
      if (el.type === this.data.type) {
        this.firstSelect = el
      }
    })
    if(this.firstSelect.name === this.$t('custom-variables')) {
      let payload = {
        orderTypeId: this.tableInfo.orderTypeId,
        orderSubtypeId: this.tableInfo.orderSubtypeId,
        specGroup: 'Variable'
      }
      this.specsTypes(payload).then((res) => {
        res.forEach(el => {
          if(el.specGroup === 'Variable' && el.id === this.data.specTypeId) {
            this.secondSelect = el
            this.secondSelectOptions.push(el)
          }else if(el.specGroup === 'Variable') {
            this.secondSelectOptions.push(el)
          }
        })
      })

      this.specsTypes(payload).then((res) => {
        res.forEach(el => {
          if (el.specGroup === 'Variable') {
            this.columns({specTypeId: el.id}).then((result) => {
              if (this.secondSelect.id === result[0]?.specTypeId) {
                result.forEach(val => {
                  if (val.id === this.data.specColumnId) {
                    this.thirdSelect = val
                  }
                  this.thirdSelectOptions.push(val)
                })
              }
            })
          }
        })
      })
    }else if(this.firstSelect.name === this.$t('taxes-and-ratios')) {
      let payload = {
        orderTypeId: this.tableInfo.orderTypeId,
        specGroup: 'Dictionary'
      }
      this.specsTypes(payload).then((res) => {
        res.forEach(el => {
          if(el.specGroup === 'Dictionary' && el.id === this.data.specTypeId) {
            this.secondSelect = el
            this.secondSelectOptions.push(el)
          }else if(el.specGroup === 'Dictionary') {
            this.secondSelectOptions.push(el)
          }
        })
      })

      this.specsTypes(payload).then((res) => {
        res.forEach(el => {
          if (el.specGroup === 'Dictionary') {
            this.columns({specTypeId: el.id}).then((result) => {
              if (this.secondSelect.id === result[0]?.specTypeId) {
                result.forEach(val => {
                  if (val.id === this.data.specColumnId) {
                    this.thirdSelect = val
                  }
                  this.thirdSelectOptions.push(val)
                })
              }
            })
          }
        })
      })
    }else if(this.firstSelect.name === this.$t('subtypes')) {
      this.columns({orderTypeId: this.tableInfo.orderTypeId}).then((res) => {
        this.secondSelectOptions = res
        res.forEach(el => {
          if (el.id === this.data.specColumnId) {
            this.secondSelect = el
          }
        })
      })
    }
  },

  methods: {
    ...mapActions(['subtypes', 'specsTypes', 'columns', 'editFormulasElement']),
    changeFirstSelect() {
      this.secondSelect = {}
      this.thirdSelect = {}
      this.secondSelectOptions = []
      this.thirdSelectOptions = []
      if (this.firstSelect.name === this.$t('subtypes')) {
        this.subtypes(this.tableInfo.orderTypeId).then(() => this.columns({orderTypeId: this.tableInfo.orderTypeId}).then((res) => this.secondSelectOptions = res))
      }else if(this.firstSelect.name === this.$t('custom-variables')) {
        let payload = {
          orderTypeId: this.tableInfo.orderTypeId,
          orderSubtypeId: this.tableInfo.orderSubtypeId,
          specGroup: 'Variable'
        }
        this.specsTypes(payload).then((res) => {
          res.forEach(el => {
            if(el.specGroup === 'Variable') {
              this.secondSelectOptions.push(el)
            }
          })
        })
      }else if(this.firstSelect.name === this.$t('taxes-and-ratios')) {
        let payload = {
          orderTypeId: this.tableInfo.orderTypeId,
          specGroup: 'Dictionary'
        }
        this.specsTypes(payload).then((res) => {
          res.forEach(el => {
            if(el.specGroup === 'Dictionary') {
              this.secondSelectOptions.push(el)
            }
          })
        })
      }

      this.firstSelect.type !== this.data.type ? this.isEdit = true : this.isEdit = false
    },
    changeSecondSelect() {
      this.thirdSelect = {}
      this.thirdSelectOptions = []
      this.isEdit = true
      if(this.firstSelect.name === this.$t('custom-variables')) {
        let payload = {
          orderTypeId: this.tableInfo.orderTypeId,
          orderSubtypeId: this.tableInfo.orderSubtypeId,
          specGroup: 'Variable'
        }
        this.specsTypes(payload).then((res) => {
          res.forEach(el => {
            if (el.specGroup === 'Variable') {
              this.columns({specTypeId: el.id}).then((result) => {
                if (this.secondSelect.id === result[0]?.specTypeId) {
                  this.thirdSelectOptions = result
                }
              })
            }
          })
        })
      }else if(this.firstSelect.name === this.$t('taxes-and-ratios')) {
        let payload = {
          orderTypeId: this.tableInfo.orderTypeId,
          specGroup: 'Dictionary'
        }
        this.specsTypes(payload).then((res) => {
          res.forEach(el => {
            if (el.specGroup === 'Dictionary') {
              this.columns({specTypeId: el.id}).then((result) => {
                if (this.secondSelect.id === result[0]?.specTypeId) {
                  this.thirdSelectOptions = result
                }
              })
            }
          })
        })
      }
    },

    changeThirdSelect () {
      this.isEdit = true
    },

    changeMathSelect () {
      this.isEdit = true
    },

    editRow() {
      let data = {
        action: this.mathVal.type,
        type: this.firstSelect.type,
        specTypeId: this.firstSelect.name === this.$t('subtypes') ? null : this.secondSelect.id,
        specColumnId: this.firstSelect.name === this.$t('subtypes') ? this.secondSelect.id : this.thirdSelect.id,
        amount: null,
        id: this.tableInfo.id,
        elementId: this.data.id
      }
      this.$emit('editRow', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  position: relative;
  display: grid;
  grid-template-columns: 80% 20%;

  &-left {
    display: flex;
    align-items: center;
    gap: 20px;

    &__title {
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: 14px;
      white-space: nowrap;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    ::v-deep .ui-select {
      max-width: 190px;
    }
  }

  &-right {
    position: absolute;
    width: 100%;
    right: -20px;
    top: 33px;
    max-width: 193px;

    ::v-deep .ui-select {
      max-width: 153px;
    }
  }
}
</style>