<template>
  <div class="formulas-table-row row">
    <div class="row-left">
      <img src="@/assets/svg/icon-delete-admin.svg" alt="" @click="$emit('deleteNewAction')"/>
      <img src="@/assets/images/check-disable.png" alt="" v-if="!formulaVal.id"/>
      <img src="@/assets/images/check-active.png" alt="" v-if="formulaVal.id" @click="addNewAction"/>
      <ui-select :placeholder="$t('not-selected')" v-model="formulaVal" :options="formulasOptions"/>
    </div>
    <div class="row-right">
      <ui-select v-model="mathVal" :options="mathOptions"/>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";

export default {
  name: "FormulasActionsTableNewRow",
  components: {UiSelect},
  props: {
    formulas: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      formulaVal: {},
      formulasOptions: [],
      mathVal: {},
      mathOptions: [
        {name: this.$t('multiply'), type: 'Multiply'},
        {name: this.$t('add-math'), type: 'Add'},
      ]
    }
  },

  mounted () {
    this.formulasOptions = this.formulas;
  },

  methods: {
    addNewAction() {
      let data = {
        action: this.mathVal.type || 'Add',
        formulaId: this.formulaVal.id
      }
      this.$emit('addNewAction', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  position: relative;

  &-left {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    ::v-deep .ui-select {
      max-width: 305px;
    }
  }

  &-right {
    position: absolute;
    width: 100%;
    left: 355px;
    top: 30px;
    max-width: 193px;
    display: flex;
    align-items: center;
    gap: 5px;

    ::v-deep .ui-select {
      max-width: 153px;
    }

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
</style>