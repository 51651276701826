<template>
  <div class="formulas">
    <div class="formulas__select">
      <div class="formulas__select-item">
        <p>{{ $t('incident-type') }}</p>
        <ui-select @input="changeTypes" :options="getTypes" v-model="incidentType" />
      </div>
      <div class="formulas__select-item">
        <p>{{ $t('subtype') }}</p>
        <ui-select @input="changeSubtype" :options="getSubtypes" v-model="subtype" />
      </div>
    </div>
    <ui-loader v-model="$store.state.adminStore.userVariablesLoader" />
    <UiNotification v-model="showNotification" :message="message" />
    <template v-if="!$store.state.adminStore.userVariablesLoader && subtype.name">
      <formulas-table
          :title="item.name"
          v-for="(item, key) in getFormulas"
          :key="key"
          :data="item"
          @addRow="addRow($event)"
          @editRow="editRow($event)"
          @deleteRow="deleteRow($event)"
          @addNewTitle="addNewTitle($event)"
      />
    </template>
    <formulas-table
        :type="tableType"
        new-table
        v-if="newTable && subtype.name"
        :title="tableType === 'addFormulasActions' ? $t('actions-with-formulas') : ''"
        @addNewTable="addNewTable($event)"
    />
    <formulas-actions-table
        :formulas="getFormulas"
        :actions="getFormulasActions"
        v-if="(showActionsTable || this.getFormulasActions.length) && !$store.state.adminStore.userVariablesLoader && subtype.name && getFormulas.length >= 1"
        @addNewAction="addNewAction($event)"
        @deleteAction="deleteAction($event)"
        @editAction="editAction($event)"
    />
    <div class="formulas__action" v-if="!newTable">
      <ui-button v-if="showTypeActions && !showActionsTable" color="error" @click="changeType('addFormulas')">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('add-formula') }}
      </ui-button>
      <ui-button v-if="showTypeActions && getFormulas.length >= 1 && !showActionsTable && !this.getFormulasActions.length" color="error" @click="showActionsTable = true">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('add-formula-actions') }}
      </ui-button>
      <ui-button v-if="!$store.state.adminStore.userVariablesLoader && subtype.name && !$store.state.adminStore.userVariablesLoader && !showActionsTable" color="error" @click="showTypeActions=!showTypeActions">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('add') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiSelect from "@/components/ui/UiSelect.vue";
import UiButton from "@/components/ui/UiButton.vue";
import FormulasTable from "@/components/common/admin/FormulasTable.vue";
import FormulasActionsTable from "@/components/common/admin/formulasActions/FormulasActionsTable.vue";
import UiNotification from "@/components/ui/UiNotification.vue";

export default {
  name: "Formulas",
  components: {UiNotification, FormulasActionsTable, FormulasTable, UiButton, UiSelect, UiLoader},
  data () {
    return {
      incidentType: { name: '', value: '' },
      subtype: { name: '', value: '' },
      loader: false,
      newTable: false,
      tableType: '',
      showTypeActions: false,
      showActionsTable: false,
      showNotification: false,
      message: ''
    }
  },

  computed: {
    ...mapGetters(['getTypes', 'getSubtypes', "getFormulas", 'getFormulasActions']),
  },

  methods: {
    ...mapActions([
      'formulas',
      'subtypes',
      'formulasActions',
      'addFormulas',
      'addFormulasAction',
      'deleteFormulasAction',
      'editFormulasAction',
      'addFormulasElement',
      'editFormulasElement',
      'deleteFormulasElement',
      'editFormula'
    ]),
    ...mapMutations(['setUserVariablesLoader']),

    changeTypes() {
      this.subtype = {}
      this.subtypes(this.incidentType.id)
    },
    changeSubtype() {
      this.setUserVariablesLoader(true)

      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };

      this.formulas(payload).then(() => {
        this.formulasActions(payload).then(() => {
          setTimeout(() => {
            this.setUserVariablesLoader(false)
          }, 500);
        })
      })

      document.querySelector('.formulas').style.overflowX = 'auto'
    },

    changeType(type) {
      this.tableType = type
      this.newTable = true
      this.showTypeActions = false
    },

    addRow(data) {
      this.setUserVariablesLoader(true)
      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };
      this.addFormulasElement(data).then(() =>
          this.formulas(payload).then(() => {
            this.formulasActions(payload).then(() => {
              setTimeout(() => {
                this.setUserVariablesLoader(false)
                this.showNotification = true
                this.message = this.$t('indicator-successfully-added')
              }, 500);
            })
          })
      )
    },

    editRow(data) {
      this.setUserVariablesLoader(true)
      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };
      this.editFormulasElement(data).then(() =>
          this.formulas(payload).then(() => {
            this.formulasActions(payload).then(() => {
              setTimeout(() => {
                this.setUserVariablesLoader(false)
                this.showNotification = true
                this.message = this.$t('indicator-successfully-update')
              }, 500);
            })
          })
      )
    },

    deleteRow(data) {
      this.setUserVariablesLoader(true)
      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };
      this.deleteFormulasElement({id: data.formulaId, elementId: data.id}).then(() =>
          this.formulas(payload).then(() => {
            this.formulasActions(payload).then(() => {
              setTimeout(() => {
                this.setUserVariablesLoader(false)
                this.showNotification = true
                this.message = this.$t('indicator-successfully-added')
              }, 500);
            })
          })
      )
    },

    addNewTable(val) {
      this.setUserVariablesLoader(true)
      this.newTable = false

      let data = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
        name: val
      };

      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };

      this.addFormulas(data).then(() =>
          this.formulas(payload).then(() => {
            this.formulasActions(payload).then(() => {
              setTimeout(() => {
                this.setUserVariablesLoader(false)
                this.showNotification = true
                this.message = this.$t('formula-successfully-added')
              }, 500);
            })
          })
      )
    },

    addNewAction(data) {
      this.setUserVariablesLoader(true)

      let info = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
        action: data.action,
        formulaId: data.formulaId
      }

      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };

      this.addFormulasAction(info).then(() => {
        this.formulas(payload).then(() => {
          this.formulasActions(payload).then(() => {
            setTimeout(() => {
              this.setUserVariablesLoader(false)
              this.showNotification = true
              this.message = this.$t('action-successfully-added')
            }, 500);
          })
        })
      })
    },

    deleteAction(data) {
      this.setUserVariablesLoader(true)

      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };

      this.deleteFormulasAction({id: data.id}).then(() => {
        this.formulas(payload).then(() => {
          this.formulasActions(payload).then(() => {
            setTimeout(() => {
              this.setUserVariablesLoader(false)
              this.showNotification = true
              this.message = this.$t('action-successfully-deleted')
            }, 500);
          })
        })
      })
    },

    editAction(data) {
      this.setUserVariablesLoader(true)

      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };

      this.editFormulasAction(data).then(() => {
        this.formulas(payload).then(() => {
          this.formulasActions(payload).then(() => {
            setTimeout(() => {
              this.setUserVariablesLoader(false)
              this.showNotification = true
              this.message = this.$t('action-successfully-update')
            }, 500);
          })
        })
      })
    },

    addNewTitle(data) {
      this.setUserVariablesLoader(true)

      let payload = {
        orderTypeId: this.incidentType.id,
        orderSubtypeId: this.subtype.id,
      };

      this.editFormula(data).then(() => {
        this.formulas(payload).then(() => {
          this.formulasActions(payload).then(() => {
            setTimeout(() => {
              this.setUserVariablesLoader(false)
              this.showNotification = true
              this.message = this.$t('formula-successfully-updated')
            }, 500);
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.formulas {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  &__select {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 490px;
    width: 100%;

    ::v-deep .ui-select {
      z-index: auto;
      max-width: 305px;
    }
    ::v-deep .ui-select__label {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      p {
        font-size: 16px;
        line-height: 19px;
        color: #1B1A1F;
        white-space: nowrap;
      }

      ::v-deep .ui-select__inner {
        @media (max-width: 1100px) {
          height: 60px;
        }
      }
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      max-width: 366px;
    }
  }
}
</style>