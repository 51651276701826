<template>
  <div class="formulas-table-row row">
    <div class="row-left">
      <div class="row-left__title">
        <p>{{ $t('indicator') }} {{ number }}</p>
        <img @click="$emit('deleteNewRow')" src="@/assets/svg/icon-delete-admin.svg" alt=""/>
        <img src="@/assets/images/check-active.png" alt="" @click="addNewRow" v-if="isAdd"/>
      </div>
      <ui-select :placeholder="$t('not-selected')" v-model="firstSelect" :options="firstSelectOptions" @input="changeFirstSelect"/>
      <ui-select :placeholder="$t('not-selected')" v-model="secondSelect" :options="secondSelectOptions" @input="changeSecondSelect"/>
      <ui-select :placeholder="$t('not-selected')" v-model="thirdSelect" :options="thirdSelectOptions"/>
    </div>
    <div class="row-right">
      <ui-select v-model="mathVal" :options="mathOptions"/>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions} from "vuex";

export default {
  name: "FormulasTableNewRow",
  components: {UiSelect},
  props: {
    number: {
      type: Number,
      default: 0
    },
    tableInfo: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      firstSelect: {},
      secondSelect: {},
      thirdSelect: {},
      mathVal: '',
      firstSelectOptions: [
        {name: this.$t('subtypes'), type: 'Subtype'},
        {name: this.$t('custom-variables'), type: 'SpecKey'},
        {name: this.$t('taxes-and-ratios'), type: 'SpecNumber'}
      ],
      secondSelectOptions: [],
      thirdSelectOptions: [],
      mathOptions: [
        {name: this.$t('multiply'), type: 'Multiply'},
        {name: this.$t('add-math'), type: 'Add'},
      ]
    }
  },

  computed: {
    isAdd() {
      return this.firstSelect.name ? true : false
    }
  },

  methods: {
    ...mapActions(['subtypes', 'specsTypes', 'columns', 'formulas']),
    changeFirstSelect() {
      this.secondSelect = {}
      this.thirdSelect = {}
      this.secondSelectOptions = []
      this.thirdSelectOptions = []
      if (this.firstSelect.name === this.$t('subtypes')) {
        this.subtypes(this.tableInfo.orderTypeId).then(() => this.columns({orderTypeId: this.tableInfo.orderTypeId}).then((res) => this.secondSelectOptions = res))
      }else if(this.firstSelect.name === this.$t('custom-variables')) {
        let payload = {
          orderTypeId: this.tableInfo.orderTypeId,
          orderSubtypeId: this.tableInfo.orderSubtypeId,
          specGroup: 'Variable'
        }
        this.specsTypes(payload).then((res) => {
          res.forEach(el => {
            if(el.specGroup === 'Variable') {
              this.secondSelectOptions.push(el)
            }
          })
        })
      }else if(this.firstSelect.name === this.$t('taxes-and-ratios')) {
        let payload = {
          orderTypeId: this.tableInfo.orderTypeId,
          specGroup: 'Dictionary'
        }
        this.specsTypes(payload).then((res) => {
          res.forEach(el => {
            if(el.specGroup === 'Dictionary') {
              this.secondSelectOptions.push(el)
            }
          })
        })
      }
    },
    changeSecondSelect() {
      this.thirdSelect = {}
      this.thirdSelectOptions = []
      if(this.firstSelect.name === this.$t('custom-variables')) {
        let payload = {
          orderTypeId: this.tableInfo.orderTypeId,
          orderSubtypeId: this.tableInfo.orderSubtypeId,
          specGroup: 'Variable'
        }
        this.specsTypes(payload).then((res) => {
          res.forEach(el => {
            if (el.specGroup === 'Variable') {
              this.columns({specTypeId: el.id}).then((result) => {
                if (this.secondSelect.id === result[0]?.specTypeId) {
                  this.thirdSelectOptions = result
                }
              })
            }
          })
        })
      }else if(this.firstSelect.name === this.$t('taxes-and-ratios')) {
        let payload = {
          orderTypeId: this.tableInfo.orderTypeId,
          specGroup: 'Dictionary'
        }
        this.specsTypes(payload).then((res) => {
          res.forEach(el => {
            if (el.specGroup === 'Dictionary') {
              this.columns({specTypeId: el.id}).then((result) => {
                  if (this.secondSelect.id === result[0]?.specTypeId) {
                    this.thirdSelectOptions = result
                  }
              })
            }
          })
        })
      }
    },
    addNewRow() {
      let data = {
        action: this.mathVal.type || 'Add',
        type: this.firstSelect.type,
        specTypeId: this.firstSelect.name === this.$t('subtypes') ? null : this.secondSelect.id,
        specColumnId: this.firstSelect.name === this.$t('subtypes') ? this.secondSelect.id : this.thirdSelect.id,
        amount: null,
        id: this.tableInfo.id
      }
      this.$emit('addRow', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  position: relative;
  display: grid;
  grid-template-columns: 80% 20%;

  &-left {
    display: flex;
    align-items: center;
    gap: 20px;

    ::v-deep .ui-select {
      max-width: 190px;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: 14px;
      white-space: nowrap;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  &-right {
    position: absolute;
    width: 100%;
    right: -20px;
    top: 33px;
    max-width: 193px;

    ::v-deep .ui-select {
      max-width: 153px;
    }
  }
}
</style>