<template>
  <div class="formulas-table-row row">
    <div class="row-left">
      <img src="@/assets/svg/icon-delete-admin.svg" alt="" @click="$emit('deleteAction', {id: item.id})"/>
      <img src="@/assets/images/check-active.png" alt="" v-if="isEdit" @click="sendEditInfo"/>
      <ui-select :placeholder="$t('not-selected')" v-model="formulaVal" :options="formulasOptions" @input="editAction"/>
    </div>
    <div class="row-right">
      <ui-select v-model="mathVal" :options="mathOptions" @input="editAction" v-if="!showMath"/>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";

export default {
  name: "FormulasActionsTableRow",
  components: {UiSelect},
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    formulas: {
      type: Array,
      default: () => []
    },
    newRow: {
      type: Boolean,
      default: false
    },
    showMath: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formulaVal: {},
      formulasOptions: [],
      mathVal: {},
      mathOptions: [
        {name: this.$t('multiply'), type: 'Multiply'},
        {name: this.$t('add-math'), type: 'Add'},
      ],
      isEdit: false
    }
  },

  mounted () {
    this.formulas.forEach(el => {
      if (el.id === this.item.formulaId) {
        this.formulaVal = el
      }
    })
    this.mathOptions.forEach(el => {
      if(el.type === this.item.action) {
        this.mathVal = el
      }
    })
    this.formulasOptions = this.formulas;
  },

  methods: {
    editAction() {
      if (this.formulaVal.id !== this.item.formulaId || this.mathVal.type !== this.item.action) {
        this.isEdit = true
      }else {
        this.isEdit = false
      }
    },
    sendEditInfo() {
      let data = {
        orderTypeId: this.item.orderTypeId,
        orderSubtypeId: this.item.orderSubtypeId,
        action: this.mathVal.type,
        formulaId: this.formulaVal.id,
        id: this.item.id
      }

      this.$emit('editAction', data)
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  position: relative;

  &-left {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    ::v-deep .ui-select {
      max-width: 305px;
    }
  }

  &-right {
    position: absolute;
    width: 100%;
    left: 355px;
    top: 30px;
    max-width: 193px;
    display: flex;
    align-items: center;
    gap: 5px;

    ::v-deep .ui-select {
      max-width: 153px;
    }

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
</style>