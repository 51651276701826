<template>
  <div :class="['formulas-table', { active: data?.id === getDeletedFormula[0]?.id}]">
    <div class="formulas-table__title" :style="{padding: !title ? '3px 10px' : '10px 20px'}">
      <h2 v-if="title && !isEditTitle" @click="deleteFormulaTable">{{ title }}</h2>
      <img
          class="edit-icon"
          src="@/assets/svg/admin/edit-default.svg"
          alt=""
          v-if="title && !isEditTitle"
          @click="isEditTitle=true"
      />
      <div class="formulas-table__title-add" v-if="!title || isEditTitle">
        <ui-input v-model="addTitle" />
        <img src="@/assets/images/check-disable.png" alt="" v-if="!addTitle && isEditTitle" @click="isEditTitle = false" :style="{width: '20px', height: '20px'}"/>
        <img src="@/assets/svg/icon-check-green.svg" v-if="addTitle" @click="changeTitle"/>
      </div>
    </div>
    <div class="formulas-table__body body" v-if="!newTable">
      <div class="body__left">
        <formulas-table-row
            v-for="(item, key) in data.elements"
            :key="key" :number="key + 1"
            :tableInfo="data"
            :data="item"
            @deleteRow="$emit('deleteRow', $event)"
            @editRow="$emit('editRow', $event)"
            :showMath="key+1 === data.elements.length && key !== 0 && !rowNew.length"
        />
        <formulas-table-new-row
            v-for="(item, key) in rowNew"
            :key="data.elements.length + key"
            :number="item.id"
            :tableInfo="data"
            @addRow="setNewRowData($event)"
            @deleteNewRow="deleteNewRow"
        />
      </div>
    </div>
    <ui-button color="outline" class="formulas-table__action" @click="addRow">
      <img src="@/assets/svg/icon-plus-red.svg" alt="">
      <p>{{ $t('add-line') }}</p>
    </ui-button>
  </div>
</template>

<script>

import UiButton from "@/components/ui/UiButton.vue";
import FormulasTableRow from "@/components/common/admin/FormulasTableRow.vue";
import UiInput from "@/components/ui/UiInput.vue";
import FormulasTableNewRow from "@/components/common/admin/FormulasTableNewRow.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "FormulasTable",
  components: {FormulasTableNewRow, UiInput, FormulasTableRow, UiButton},
  props: {
    addNewRow: {
      type: Boolean,
      default: false
    },
    addNewTable: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    newTable: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  mounted () {
    this.rowNew = []
  },

  data () {
    return {
      addTitle: '',
      newRow: [
        {
          id: 1
        },
      ],
      rowNew: [],
      isEditTitle: false
    }
  },

  methods: {
    ...mapMutations(['deleteFormula']),
    addRow() {
      if(!this.rowNew.length) {
        this.rowNew.push({id: this.data ? this.data.elements.length + this.rowNew.length + 1 : 1})
      }
    },
    setNewRowData(data) {
      this.$emit('addRow', data)
      this.rowNew = []
    },
    deleteNewRow() {
      this.rowNew = []
    },
    deleteFormulaTable() {
      this.deleteFormula(this.data)
    },
    changeTitle () {
      if (!this.title) {
        this.$emit('addNewTable', this.addTitle)
      }else {
        let payload = {
          id: this.data.id,
          name: this.addTitle
        }
        this.$emit('addNewTitle', payload)
      }
    }
  },

  computed: {
    ...mapGetters(['getDeletedFormula'])
  }
}
</script>

<style lang="scss" scoped>
.formulas-table {
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
  border: 1px solid transparent;
  transition: 0.3s;
  min-width: 950px;
  max-width: 950px;

  &.active {
    border: 1px solid red;
  }

  &__title {
    padding: 10px 20px;
    width: 100%;
    background: #E21F1F;
    border-radius: 20px 20px 0 0;
    height: 37px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: 900px;

    @media (max-width: 1200px) {
      min-width: 950px;
      max-width: 950px;
    }

    .edit-icon {
      width: 14px;
      height: 14px;
    }

    &-add {
      display: flex;
      align-items: center;
      gap: 5px;
      max-width: 240px;

      ::v-deep input {
        height: 30px;
        color: #FFFFFF;
        max-width: 200px;
      }

      img {
        cursor: pointer;
      }
    }

    h2 {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 30px 38px 0 20px;
    min-width: 900px;

    @media (max-width: 1200px) {
      min-width: 950px;
      max-width: 950px;
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__right {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 33px;

      ::v-deep .ui-select {
        max-width: 158px;
      }
    }
  }

  &__action {
    width: max-content !important;
    padding: 30px 20px !important;

    &:hover {
      text-decoration: none !important;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #CE2121;
    }
  }
}
</style>